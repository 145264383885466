import Error from '../../util/Error'

const SUPPLIER_STORAGE_SERVICE_CONTACTS_STATE = {
    token: null,
    id: null,
    error: false,
    free_storage_days: null,
    daily_storage_price: null,
    handling_price: null,
    brand_id: [],
    model_id: [],
    currency: null,
};

export default {
    data() {
        return {
            supplier_storage_service: {...SUPPLIER_STORAGE_SERVICE_CONTACTS_STATE},
            supplier_storage_services: [],
        }
    },
    methods: {
        handleAddUpdateSupplierStorageServiceClick() {
            this.supplier_storage_service.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.supplier_storage_service.brand_id.length) {
                this.supplier_storage_service.error = true;
                errors.brand_id = [this.$t('validation.required')];
            }
            if (!this.supplier_storage_service.model_id.length) {
                this.supplier_storage_service.error = true;
                errors.model_id = [this.$t('validation.required')];
            }
            if (!this.supplier_storage_service.daily_storage_price) {
                this.supplier_storage_service.error = true;
                errors.daily_storage_price = [this.$t('validation.required')];
            }
            if (!this.supplier_storage_service.free_storage_days) {
                this.supplier_storage_service.error = true;
                errors.free_storage_days = [this.$t('validation.required')];
            }
            if (!this.supplier_storage_service.handling_price) {
                this.supplier_storage_service.error = true;
                errors.handling_price = [this.$t('validation.required')];
            }

            if (!this.supplier_storage_service.currency) {
                this.supplier_storage_service.error = true;
                errors.currency = [this.$t('validation.required')];
            }

            _.map(this.supplier_storage_services, (supplier_storage) => {

                if (supplier_storage.token !== this.supplier_storage_service.token) {

                    if (_.filter(supplier_storage.brand_id,(i) => _.includes(this.supplier_storage_service.brand_id,i)).length) {
                        errors.brand_id = [this.$t('validation.duplicate')];
                        this.supplier_storage_service.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }

                    if (_.filter(supplier_storage.model_id,(i) => _.includes(this.supplier_storage_service.model_id,i)).length) {
                        errors.model_id = [this.$t('validation.duplicate')];
                        this.supplier_storage_service.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.supplier_storage_service.error) return false;

            const entity = this.supplier_storage_service;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.supplier_storage_services, {token: entity.token});

            if (this.supplier_storage_services[index] !== undefined) {
                this.supplier_storage_services[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                this.supplier_storage_services.push(entity)
            }

            this.supplier_storage_service = {
                ...SUPPLIER_STORAGE_SERVICE_CONTACTS_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleEditSupplierStorageServiceDeleteClick(token) {
            const index = _.findIndex(this.supplier_storage_services, {token: token})
            if (this.supplier_storage_services[index] !== undefined) {
                this.supplier_storage_services.splice(index, 1);
            }
        },
        handleEditSupplierStorageServiceEditClick(token) {
            const index = _.findIndex(this.supplier_storage_services, {token: token})
            if (this.supplier_storage_services[index] !== undefined) {
                this.supplier_storage_service = {...this.supplier_storage_services[index]};
            }
        },
        resetSupplierStorageService() {
            this.supplier_storage_service = {...SUPPLIER_STORAGE_SERVICE_CONTACTS_STATE}
        },
    }
}
